import { LinkContainer } from '../link/link.styles'

const LinkComponent = ({ children, to, $mode, $fontSize, refContainer, disabled=false, onClick, as, $activated=false, $elipsis=false, $shadow=false, $iconRight, ...props }) => {
  return (
    disabled ? (
      <LinkContainer className='_refLink' as='a' to={to}  onClick={e => e.preventDefault()} $mode={$mode} $fontSize={$fontSize} $shadow={$shadow ? 1 : 0} ref={refContainer} $activated={$activated  ? 1 : 0} $elipsis={$elipsis  ? 1 : 0} disabled={disabled ? 1 : 0} $iconRight={$iconRight} {...props}>{children}</LinkContainer>
    ) : (
      <LinkContainer className='_refLink' as={as} to={to} $mode={$mode} $fontSize={$fontSize} $shadow={$shadow ? 1 : 0} ref={refContainer} onClick={onClick} $activated={$activated  ? 1 : 0} $elipsis={$elipsis  ? 1 : 0} $iconRight={$iconRight} {...props}>{children}</LinkContainer>
    )
  )
}

export default LinkComponent