import { useNavigate } from 'react-router-dom'

import { client, forstokCookies } from '../../../utils/forstok'

import LinkComponent from '../../../components/link/link.component'

import { isProduction } from '../../../assets/javascripts/function'

import Auth from './auth/auth'

export const destroyUser = ( callback ) => {
  return client.clearStore().then(() => {
    if(process.env.NODE_ENV === 'development') {
      forstokCookies.remove('token',{ path: '/' })
      forstokCookies.remove('permission',{ path: '/' })
      forstokCookies.remove('password',{ path: '/' })
      forstokCookies.remove('users',{ path: '/' })
    }else {
      forstokCookies.remove('token',{ path: '/', domain:'.forstok.com' })
      forstokCookies.remove('permission',{ path: '/', domain:'.forstok.com' })
      forstokCookies.remove('password',{ path: '/', domain:'.forstok.com'})
      forstokCookies.remove('users',{ path: '/', domain:'.forstok.com' })
    }
    window.sessionStorage.clear()
    window.localStorage.clear()
    if (callback && typeof(callback) == "function") callback()
  })
}

export const expiredUser = () => {
  return destroyUser(() => {
    forstokCookies.set('referrerURL', window.location.pathname+window.location.search+window.location.hash,{ path: '/'})
    window.location.href = '/dashboard/users/login?k=exp'
  })
}

const Logout = ({ mode }) => {
  const navigate = useNavigate()
  
  const handleClick = e => {
    e.preventDefault()
    destroyUser(() => {
      forstokCookies.remove('referrerURL',{ path: '/' })
      if(isProduction()) {
        Auth.logout(() => window.location.href = 'https://new.forstok.com')
      }else {
        Auth.logout(() => navigate('/'))
      }
      if(process.env.REACT_APP_WEB_CHAT_HOST) window.fcWidget && window.fcWidget.destroy()
    })
  }
  return (
    <LinkComponent $mode={mode || 'clearR'} as='a' to='#' onClick={handleClick}>Logout</LinkComponent>
  )
}
export default Logout