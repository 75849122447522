import '../assets/stylesheets/font.css'
import '../assets/stylesheets/common.css'

import styled, { css, createGlobalStyle } from 'styled-components'

const RED_FORSTOK = '#fc5c64',
      BLUE_FORSTOK = '#429ddf',
      WHITE_FORSTOK = '#f7f7f7'

export const GlobalStyle = createGlobalStyle`
  :root {
    --pri-ft-fm: 'Proxima Nova', sans-serif;
    --sec-ft-fm: 'Lato';

    --pri-ft-sz: 14px;

    --pri-clr: #2c2c2c;
    --sec-clr: #ffffff;
    --ter-clr: #000000;
    --mt-clr: #808080;
    --act-clr: #ffffff;
    --sta-clr: ${RED_FORSTOK};
    --err-clr: ${RED_FORSTOK};
    --inp-clr: #2d3c48;
    --hd-clr: #535353;

    --pri-clr-lnk__hvr: ${BLUE_FORSTOK};
    --pri-clr-lnk: #505050;
    --pri-clr-lnk__act: #000000;
    --sec-clr-lnk__hvr: ${RED_FORSTOK};

    --pri-clr-ln: #eeeeee;
    --pri-clr-ln__fc: ${BLUE_FORSTOK};
    --sec-clr-ln: #e4e5e5;
    --lay-clr-ln: #f5f5f5;
    --ck-clr-ln: #cccccc;
    --ter-clr-ln: #f5f5f5;
    --sel-clr-ln: #21BA45;
    --act-clr-ln: #FF585C;
    --err-clr-ln: ${RED_FORSTOK};
    --tab-clr-ln: #D5D5D5;

    --pri-clr-bg: #ffffff;
    --sec-clr-bg: ${WHITE_FORSTOK};
    --ter-clr-bg: #f5f5f5;
    --mt-clr-bg: #c4c4c4;
    --mt-clr-bg__fc: #F1F1F1; 
    --act-clr-bg: ${RED_FORSTOK};
    --act-clr-bg__hvr: #ec5b62;
    --cl-clr-bg: #ffffff;
    --cl-clr-bg__hvr: ${WHITE_FORSTOK};
    --ov-clr-bg: rgba(0,0,0,.25);
    --ck-clr-bg__act: ${BLUE_FORSTOK};
    --hg-clr-bg: #f0fcff;
    --hg-clr-bg__fc: #fffbea;
    --sw-clr-bg: rgba(0, 0, 0, 0.2);
    --sw-clr-bg__fc: #55d451;
    --hd-clr-bg: rgb(250,250,250);

    --pri-rd: 12px;
    --sec-rd: 10px;
    --ter-rd: 4px;

    --pri-shd-bx: rgb(28 28 28 / 8%) 0px 6px 8px;
    --sec-shd-bx: rgb(232 232 232) 0px -0.5px 0px inset;
    --act-shd-bx: rgba(0, 0, 0, 0.2) 2px 2px 8px;
    --img-shd-bx: rgb(0 0 0 / 30%) 0px 0px 2px;

    --checkbox: 16px;
    --item: minmax(165px, 380px);
    --time: minmax(100px, 105px);
    --time-pd20: minmax(120px, 125px);
    --variant: minmax(90px, 160px);
    --variant-small: minmax(90px, 130px);
    --variant-pd20: minmax(110px, 200px);
    --sku: minmax(90px, 155px);
    --sku-pd20: minmax(110px, 175px);
    --price: minmax(95px, 120px);
    --price-small: minmax(95px, 110px);
    --price-big: minmax(105px, 140px);
    --price-pd20: minmax(115px, 140px);
    --status: minmax(85px, 120px);
    --status-small: minmax(70px, 100px);
    --status-big: minmax(95px, 140px);
    --status-pd20-big: minmax(115px, 160px);
    --warehouse: minmax(95px, 200px);
    --qty: minmax(68px, 88px);
    --qty-pd20: minmax(88px, 108px);
    --qty-small: minmax(68px, 70px);
    --qty-big: minmax(95px, 110px);
    --qty-pd20-big: minmax(115px, 130px);
    --id: minmax(80px, 100px);
    --id-pd20: minmax(120px, 130px);
    --id-big: minmax(95px, 110px);
    --person: minmax(80px, 160px);
    --email: minmax(80px, 200px);
    --ref: minmax(85px, 110px);
    --ref-pd20: minmax(85px, 150px);
    --ref-big: minmax(85px, 200px);
    --store: minmax(115px, 225px);
    --store-big: minmax(115px, 450px);
    --idate: minmax(180px, 220px);
    --action: minmax(175px, 190px);
    --statusnow: minmax(125px, 180px);

    --item-min: 160px;
    --item-max: 300px;
    --sku-min: 85px;
    --sku-max: 140px;
    --warehouse-min: 95px;
    --warehouse-max: 180px;
    --qty-min: 68px;
    --qty-max: 88px;
    --qty-big-min: 95px;
    --qty-big-max: 110px;
    --qty-large-min: 85px;
    --qty-large-max: 140px;
    --qty-pd20-big-min: 115px;
    --qty-pd20-big-max: 130px;
    --time-min: 100px;
    --time-max: 105px;
    --time-pd20-min: 120px;
    --time-pd20-max: 125px;
  }
`

const layoutStyles = css`
  display: grid;
`

const getSingleLayoutModifiedStyled = ({backgroundColor}) => {
  let stylesLoader = ''
  if(backgroundColor) {
    stylesLoader += `
      background-color: ${backgroundColor};
    `
  }
  return stylesLoader
}
const getSingleLayoutWrapperModifiedStyled = ({mode}) => {
  let stylesLoader = ''
  if(mode === 'full') {
    stylesLoader += `
      max-width: none;
      padding: 0;
      @media only screen and (min-width: 1280px) {
        max-width: none;
        padding: 0;
      }
    `
  }
  return stylesLoader
}

export const SingleLayoutContainer = styled.section`
  ${layoutStyles}
  grid-template-areas:  'header'
                    'content';
`
export const SingleLayoutBody = styled.section`
  display: grid;
  margin-top: 50px;
  background-color: #ffffff;
  ${getSingleLayoutModifiedStyled}
  @media only screen and (max-width: 767px) {
    margin-top: 0;
    z-index: 10;
  }
`
export const SingleLayoutWrapper = styled.article`
  display: grid;
  justify-self: center;
  width: 100%;
  padding: 1em 0;
  align-items: start;
  min-height: calc(100vh - 60px);
  align-content: start;
  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
    padding: 2em 3em;
  }
  ${getSingleLayoutWrapperModifiedStyled}
`
export const OrigamiLayoutContainer = styled.section`
  ${layoutStyles}
  grid-template-areas:  'header'
                        'content';
` 
export const OrigamiLayoutBody = styled.section`
  /* background-color: #FAFAFA; */
`
export const LayoutContainer = styled.article`
  position: relative;
  overflow: auto;
  height: calc(100vh - 50px);
  scroll-behavior: smooth;
`