import { type AnchorHTMLAttributes, type ReactNode, type Ref, type MouseEvent, type HTMLAttributes, lazy } from 'react'
import { Path } from 'react-router-dom'

import { AnchorLinkContainer, ElLinkContainer } from './link_ts.styles'

const LinkRouteComponent = lazy(() => import('./link_route_ts'))

type TLinkBase = {
  children: ReactNode
  $mode?: string
  $activated?: boolean
  $elipsis?: boolean
  $shadow?: boolean
  $iconRight?: string
  disabled?: boolean
} & (
  (AnchorHTMLAttributes<HTMLAnchorElement> & {
    href?: string
    to?: undefined
    refContainer?: Ref<HTMLAnchorElement>
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  }) | 
  (AnchorHTMLAttributes<HTMLAnchorElement> & {
    href?: undefined
    to: string | Partial<Path>
    refContainer?: Ref<HTMLAnchorElement>
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  }) | 
  (HTMLAttributes<HTMLSpanElement> & {
    href?: undefined
    to?: undefined
    refContainer?: Ref<HTMLSpanElement>
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void
  })
)

const LinkComponent = ({ children, $mode, refContainer, $activated=false, href, to, $elipsis=false, $shadow=false, $iconRight, disabled=false, ...props }: TLinkBase) => {
  const { onClick } = props
  return href ? (
    <AnchorLinkContainer 
      className='_refLink' 
      $mode={$mode} 
      ref={refContainer} 
      $activated={$activated} 
      $elipsis={$elipsis} 
      $shadow={$shadow} 
      disabled={disabled} 
      href={href} 
      $iconRight={$iconRight} 
      {...props}
    >
      {children}
    </AnchorLinkContainer> 
  ) : ( 
    to ? (
      <LinkRouteComponent 
        to={to} 
        className='_refLink' 
        $mode={$mode}
        $activated={$activated} 
        $elipsis={$elipsis} 
        $shadow={$shadow} 
        $iconRight={$iconRight} 
        {...props}
      >
        {children}
      </LinkRouteComponent> 
    ) : (
      <ElLinkContainer 
        className='_refLink' 
        $mode={$mode} 
        ref={refContainer} 
        $activated={$activated} 
        $elipsis={$elipsis} 
        $shadow={$shadow} 
        disabled={disabled} 
        $iconRight={$iconRight} 
        onClick={onClick} 
        {...props}
      >
        {children}
      </ElLinkContainer>)
    )
}

export default LinkComponent