import { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import MyAbility from '../../../../utils/ability'

import cookies from '../../../../utils/cookies'
import { client } from '../../../../utils/forstok'

import { Can } from '../../../../components/can/can.component'

import { getStorage, setStorage } from '../../../../assets/javascripts/function'
import { getPermission } from '../../../../assets/javascripts/config'

import Auth from './auth'
import { scanUser } from '../log'
import { expiredUser } from '../logout_ts'

const ProtectedRoute = (props) => {
  const users = getStorage('users', undefined, true);
  if(!users || !users?.profile_id || !users?.id || !users?.credentials) {
    const usersCookies = cookies.get('users')
    if(usersCookies) {
      setStorage('users', JSON.stringify(usersCookies), 'local')
    } else {
      expiredUser()
    }
  }

  const [ isFirstLoad, setFirstLoad ] = useState(true),
        [ Authenticated, setAuthenticated ] = useState(cookies.get('token') ? true : false)

  useEffect(() => {
    if (isFirstLoad) {
      if (Auth.isAuthenticated()) {
        scanUser(cookies.get('token')).then((result) => {
          if (result) setAuthenticated(true)
          else setAuthenticated(false)
        }).catch(error => {
          console.error('Error Checking Login', error)
          if (!cookies.get('token')) setAuthenticated(false)
        })
      }

      const updateAvailibity = async () => {
        const permission = cookies.get('permission') || null
        if (permission && permission.length) {
          let results = permission.map(_permission => { 
            return {id: _permission.id, group: _permission.group, name: _permission.name}
          })
          MyAbility.update(getPermission(results))
        }
      }
      updateAvailibity().catch((error) => {
        console.log('Error update Availibity', error)
      })
      setFirstLoad(false)
    }
  }, [isFirstLoad])
  
  const { as } = props   
  
  const ExcludeCan = []

  if (!isFirstLoad) {
    if (Authenticated === undefined) {
      return null;
    } else {
      if (Authenticated) {
        if (MyAbility && (as && !ExcludeCan.includes(as))) {
          return (
            <Can I='load' a={as} ability={MyAbility} passThrough>
              { can => can ? (
                  <ApolloProvider client={client}>
                    <Outlet />
                  </ApolloProvider>
              ): <Navigate to='/restricted' /> }
            </Can>
          );
        } else {
          return (
            <ApolloProvider client={client}>
              <Outlet />
            </ApolloProvider>
          );
        }
      } else {
        expiredUser();
        return null;
      }
    }
  }
  else return null
}

export default ProtectedRoute